<template>
  <div id="wrapper" :class="{'mobile' : isMobile}">
    <div-header :logged = logged ></div-header>
    <!-- <div-navi></div-navi> -->
    <div id="content" :class="{'login_class' : logged}">

      <router-view></router-view>

    </div>
    <div-footer></div-footer>

  </div>
</template>

<script>
  import DivHeader from './Header.vue'
  // import DivNavi from './Navigation.vue'
  import DivFooter from './Footer.vue'
  import {
    mapGetters
  } from 'vuex'

  export default {
    name: 'Layout',
    data: () => ({
      logged: false,
    }),
    watch: {
      partnerCode() {
        if (!this.$store.getters.partnerCode || !this.$store.state.sessionToken) {
        this.logged = false
      } else {
        if(this.$route.name == "Main" || this.$route.name == "Login") {
          this.logged = false
        } else{ 
          this.logged = true
        }
        
      }
      }
    },
    computed: {
      ...mapGetters(["loginName", "partnerCode", "partnerStatus"]),
      isMobile() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      }
    },
    methods: {
      
    },
    components: {
      DivHeader,
      // DivNavi,
      DivFooter
    },
    updated(){
      console.log('updated Layout')
      if (!this.$store.getters.partnerCode || !this.$store.state.sessionToken) {
        this.logged = false
      } else {
        if(this.$route.name == "Main" || this.$route.name == "Login") {
          this.logged = false
        } else{ 
          this.logged = true
        }
        
      }
    },
    mounted() {
      console.log('Layout ',this.$route.name)
      if (!this.$store.getters.partnerCode || !this.$store.state.sessionToken) {
        this.logged = false
      } else {
        if(this.$route.name == "Main" || this.$route.name == "Login") {
          this.logged = false
        } else{ 
          this.logged = true
        }
        
      }

    }
  }

</script>
