<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  })
}
</script>
<!-- multi select style -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
