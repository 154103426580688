<template>
  <div>
    <div id="header_main" v-if="!logged">
      <div class="header_wrap">
        <div class="partner_nav">
          <ol>
            <ul>
              <a @click="moveToMain">
                <h1>싼톡 - 파트너</h1>
              </a>
            </ul>
            <ul class="focasable_nav">
              <div class="menu">약관 및 정책</div>
              <div class="nav_content">
                <div class="sub_title view_mob">약관 및 정책</div>
                <a @click="nav('PolicyPageTerms')">이용약관</a>
                <!-- <a @click="nav('PolicyPageOperation')">운영정책</a> -->
                <a @click="nav('PolicyPagePrivacy')">개인정보처리방침</a>
              </div>
            </ul>
          </ol>
          <!-- <ol>
            <li><a href="#">도움말</a></li>
            <li><a @click="nav('MemberAnnounce')">공지사항</a></li>
          </ol> -->
        </div>
        <div class="signup_area">
          <a class="pc" @click="nav('Login')">로그인</a>
          <a class="pc" @click="nav('SignUpPartner')">파트너 가입</a>
          <a class="mobile" @click="nav('Login')">로그인</a>
        </div>
        <ModalView v-if="isWithraw">
          <component v-bind:is="modalCompo" @close-modal="isWithraw = false"></component>
        </ModalView>
      </div>
    </div>
    <div id="header" v-if="logged">
      <!-- 모바일 화면 -->
      <div class="header_wrap view_mob">
        <div class="navi">
          <a class="menu" @click="mouseCheck()">메뉴</a>
          <div class="nav_detail" v-show="isNavClick">
            <a @click="nav('MemberDashboard')" v-if="logged">홈</a>
            <div class="sub_title">리포트</div>
            <a @click="nav('ReportCustomer')">고객 리포트</a>
            <a @click="nav('ReportPartner')">파트너 리포트</a>
            <a @click="nav('ReportProfit')">수익 리포트</a>
            <a @click="nav('ReportPayment')">지급 리포트</a>
            <div class="sub_title">약관 및 정책</div>
            <a @click="nav('PolicyPageTerms')">이용약관</a>
            <a @click="nav('PolicyPagePrivacy')"> 개인정보처리방침</a>
          </div>
        </div>
        <a @click="nav('MemberDashboard')">
          <h1>싼톡 - 파트너</h1>
        </a>
        <div class="user_menu">
          <a class="user_button">사용자</a>
          <div class="user_menu_detail">
            <dl class="referral_code">
              <dt>추천인코드</dt>
              <dd>ST1234567</dd>
            </dl>
            <a @click="nav('PartnerEditInfo')">회원정보 변경</a>
            <a @click="nav('PartnerWithraw')"
               v-if="partnerStatus!='WITHDRAWAL_PROGRESS' && partnerStatus!='WITHDRAWAL'">
              파트너 탈퇴
            </a>
            <a @click="doLogOut()">로그아웃</a>
          </div>
        </div>
      </div>
      <div class="header_wrap view_pc">
        <div class="partner_nav">
          <ol>
            <ul>
              <a @click="moveToMain">
                <h1>싼톡 - 파트너</h1>
              </a>
            </ul>
            <ul>
              <a @click="nav('MemberDashboard')" v-if="logged">홈</a>
            </ul>
            <ul>
              <a class="focasable_nav" v-if="logged">리포트</a>
              <div class="nav_content">
                <a @click="nav('ReportCustomer')">
                  고객 리포트
                </a>
                <a @click="nav('ReportPartner')">
                  파트너 리포트
                </a>
                <a @click="nav('ReportProfit')">
                  수익 리포트
                </a>
                <a @click="nav('ReportPayment')">
                  지급 리포트
                </a>
              </div>
            </ul>
            <ul>
              <a class="focasable_nav">약관 및 정책</a>
              <div class="nav_content">
                <a @click="nav('PolicyPageTerms')">이용약관</a>
                <!-- <a @click="nav('PolicyPageOperation')">운영정책</a> -->
                <a @click="nav('PolicyPagePrivacy')"> 개인정보처리방침</a>
              </div>
            </ul>
          </ol>
          <!-- <ol>
            <a href="#">도움말</a>
            <a @click="nav('MemberAnnounce')">공지사항</a>
          </ol> -->
        </div>
        <div class="header_right">
          <div v-if="logged">
            <dl>
              <dt>추천인 코드</dt>
              <dd>{{partnerCode}}</dd> &nbsp;&nbsp;&nbsp;&nbsp;
            </dl>
            <dl class="user_info">
              <div class="user_name">
                <img style="width:30px;margin-right:5px" src="resources/images/user_temp.png" alt="my_page">
                {{loginName}}
              </div>
              <div class="nav_content">
                <a @click="nav('PartnerEditInfo')">회원정보 변경</a>
                <a @click="nav('PartnerWithraw')"
                  v-if="partnerStatus!='WITHDRAWAL_PROGRESS' && partnerStatus!='WITHDRAWAL'">
                  파트너 탈퇴
                </a>
                <a @click="doLogOut()">로그아웃</a>
              </div>
            </dl>
          </div>
        </div>
        <ModalView v-if="isWithraw">
          <component v-bind:is="modalCompo" @close-modal="isWithraw = false"></component>
        </ModalView>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapActions,
    mapGetters
  } from 'vuex'

  import filter from '@/plugins/filters';
  import PartnerWithraw from '../../member/PartnerWithraw.vue'
  import ModalView from "@/views/components/ModalView.vue";

  // const js = require('@/assets/common');
  export default {
    name: 'Header',

    components: {
      PartnerWithraw,
      ModalView
    },
    props:{
      logged : Boolean
    },
    data() {
      return {
        isWithraw: false,
        modalCompo: null,
        status_withraw: false,
        isNavClick:false,
      }
    },
    watch: {
      partnerCode() {
        // console.log('partnerCode', this.$store.getters.partnerCode)

      }
    },
    computed: {
      ...mapGetters(["pageName", "loginName", "partnerCode", "partnerStatus"]),

    },
    filters: {
      ...filter
    },
    methods: {
      ...mapActions(["goMainPage", "doLogOut"]),
      moveToMain() {
        if (this.$props.logged) {
          this.nav('MemberDashboard')
        } else {
          this.nav('Main')
        }
      },
      nav(name) {
        this.isNavClick = false
        if (name === this.$route.name) {
          this.$router.go(this.$router.currentRoute) //NavigationDuplicated 처리(새로고침 한 것 처럼..)
        }
        // console.log(this.$route.query)
        if (Object.keys(this.$route.params).length !== 0) {
          this.$router.push({
            name: name,
            query: {
              recommendCode: this.$route.params.recommendCode
            }
          })
        } else if (Object.keys(this.$route.query).length !== 0) {
          this.$router.push({
            name: name,
            query: {
              recommendCode: this.$route.query.recommendCode
            }
          })
        } else {
          this.$router.push({
              name: name,
            }

          )
        }
      },
      showWithraw: function () {
        this.modalCompo = PartnerWithraw;
        this.isWithraw = true;
      },
      mouseCheck(){ 
        // console.log('mouseCheck')
        this.isNavClick = true

        window.addEventListener('click',this.outerClick)

      },
      outerClick(e){ 
        // console.log('outerClick',e.target )
        
        this.resultList= []
        if(e.target != document.querySelector('.menu')) {
          this.isNavClick = false
          window.removeEventListener('click', this.outerClick)
        }

      },

    },
    updated(){

    },
    mounted() {
      console.log('Header ',this.$route.name)

    },
  }

</script>
<style>
@media screen and (max-width: 480px){
  #header .header_wrap .navi .nav_detail {
      display: flex;
  }
}

  #header .user_info.top-nav {
    position: absolute;
    top: 15px;
    left: 100px;
    font-size: 14px;
  }

  #header .user_info.top-nav dt {
    padding-left: 1rem;
    padding-right: 2.5rem;
    cursor: pointer;
  }

  #header .user_info {
    right: 50px;
  }

  .focasable_nav:hover+.nav_content {
    display: block;
  }

  #header .user_info dt {
    cursor: pointer;
  }

input::-ms-input-placeholder {color: #9b9b9b; }
input::-webkit-input-placeholder {color: #9b9b9b; }
input::-moz-placeholder {color: #9b9b9b; }

</style>
