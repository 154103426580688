<template>
  <div class="withdraw main_contents">
    <h2>파트너 탈퇴</h2>
    <div class="withdraw_conts">
      <div class="info_text">
        <h4> 안내 사항 </h4>
        <ul>
          <li> 탈퇴 신청 후 정산 관련 기능을 제외한 파트너 기능은 사용할 수 없습니다. </li>
          <li> 탈퇴 신청일 기준 잔여수익금이 <strong class="highlight">5만원 이상</strong>인 경우 지급이 완료된 후 탈퇴 절차가 완료됩니다. </li>
          <li> 탈퇴 신청일 이후 발생하는 수익은 지급받을 수 없습니다. </li>
          <li> 탈퇴 절차가 완료되면 기존 정보 확인은 불가능합니다. </li>
          <li> 탈퇴 절차가 완료된 후 재가입이 가능합니다. </li>
          <li> 탈퇴 신청은 번복될 수 없습니다. </li>
        </ul>
      </div>
      <ol>
        <dl>
          <dt>잔여 수익금</dt>
          <dd>
            <p> 잔여 수익금을 지급 받으시겠습니까?</p>
            <p>
              <input type="radio" id="usercerti" name="usercerti" @click="receiveRemainBalance=true"
                     :checked="receiveRemainBalance == true" :disabled="lowerProfit">
              <label for="usercerti"><span>예</span></label> &nbsp; &nbsp;
            </p>
            <p>
              <input type="radio" id="usercerti0" name="usercerti" @click="receiveRemainBalance=false"
                     :checked="receiveRemainBalance == false">
              <label for="usercerti0"><span>아니오</span></label>
            </p>
            <p class="error_txt">최종 승인 전 탈퇴를 진행할 경우, 잔여 수익금을 받을 수 없습니다.</p>
          </dd>
        </dl>
      </ol>
      <ol class="withdrawal_check">
        <input type="checkbox" id="ad_check" v-model="confirmCheck">
        <label for="ad_check"><span>위 안내 사항을 모두 인지하였습니다. </span></label>
        <!-- <button class="bt-md-ra" :class="{'disable' : !confirmCheck}" :disabled="!confirmCheck"
            @click="submitWithraw">탈퇴</button> -->
        <button class="btn btn_signup" :class="{'on' : confirmCheck}" :disabled="!confirmCheck"
          @click="submitWithraw">
          탈  &nbsp; 퇴
        </button>
      </ol>
    </div>
  </div>
</template>

<script>
  import filter from "@/plugins/filters"
  // import ModalHandler from "@/classes/ModalHandler";
  //import moment from "moment"

  export default {
    name: 'PartnerWithraw',
    props: {
      // title: {
      //   type: String
      // },
      // dialog: {
      //   type: ModalHandler
      // },
      // closeName: {
      //   type: String
      // },
      // modalSizeUp: {
      //   type: String
      // },
    },
    components: {},
    data() {
      return {
        confirmCheck: false,
        receiveRemainBalance: false,
        lowerProfit: true, // 5만원 이하일때 true
      }
    },
    filters: {
      ...filter
    },
    computed: {

    },
    methods: {
      async submitWithraw() {
        if(confirm('정말 탈퇴하시겠습니까? ')){
        try {
          if (this.isSubmit) {
            console.log("already submit");
            return;
          } else {
            this.isSubmit = true;
          }
          const res = await this.$axios.post('/api/partner/account/withdrawal', {
            'receiveRemainBalance': this.receiveRemainBalance,
          })

          if (res.data.success) {
            alert('회원 탈퇴 신청이 완료 되었습니다. ');
            this.$router.replace({
              name:"MemberDashboard"
            })

          } else if (res.data.code == 402) {
            alert('회원 탈퇴에 실패했습니다. 관리자에게 문의하세요. ')
          } else if (res.data.code == 603) {
            alert('예약 중인 문자메세지가 있어 탈퇴가 불가능합니다.')
          } else {
            alert('회원 탈퇴 중 오류가 발생했습니다. 다시 시도해주세요. ')
          }
          this.isSubmit = false;

        } catch (err) {
          console.error(err)
          alert('회원 탈퇴에 실패했습니다. 관리자에게 문의하세요. 에러 메세지: ', err)
          this.isSubmit = false;
        }
        }
        return
      },

    },
    mounted() {
      if(this.$store.state.balance.currentBalance >= 50000) {
        this.lowerProfit = false;
      }
    }

  }

</script>
<style >
  @media screen and (max-width: 480px){
    .withdraw.main_contents{
      padding: 20px!important;
      margin: 10px auto 0 auto!important;
      width: 94%;
    }
  }
</style>
<style scoped>
  .confirm-div {
    margin-top: 100px;
  }

  .pop .pop_footer {
    justify-content: space-between;
  }

.btn_signup.on {
    opacity: 1;
}
</style>
