import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import vuescrollto from './plugins/vuescrollto'
import filter from './plugins/filters';
import Multiselect from 'vue-multiselect'
import ActionPopup from '@/views/components/ActionPopup';
import AlertPopup from '@/views/components/AlertPopup';
import Paging from "@/views/components/Paging";
import jQuery from 'jquery';
import moment from 'moment';
// import cretePersistedState from "vuex-persistedstate";

moment.locale('ko');
// register globally
Vue.component('multiselect', Multiselect); 
Vue.component('actionPopup', ActionPopup);
Vue.component('Paging', Paging);
Vue.component('alertPopup', AlertPopup);


global.$ = jQuery
Vue.config.productionTip = false

for (const key in filter) {
  Vue.filter( key , filter[key]);
}

new Vue({
  router,
  store,
  vuetify,
  vuescrollto,
  render: h => h(App)
}).$mount('#app')
