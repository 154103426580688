<template>
  <div class="modal"  @close-modal = 'closeModal'>
    <slot />
  </div>
</template>

<script>
import ModalHandler from "@/classes/ModalHandler";

export default {
  name: "ModalView",
  props: {
    dialog: { type: ModalHandler },
  },
  data: () => ({

  }),
  computed: {},
  methods: {
    closeModal () {
        this.$emit('close-modal');
      },

  },
};
</script>
<style>
/* Modal */
.modal{display:flex;
justify-content:center;
  align-items:center;
  position:fixed; width: 100%; height: 100%; left: 0; top: 0; z-index: 998; background:rgba(0, 0, 0, 0.8)}
</style>

