module.exports = {
  AUTH_USER_STORAGE: 'ssantalk-user-storage',
  AUTH_PARTNER_STORAGE: 'ssantalk-partner-storage',
  AUTH_ADMIN_STORAGE: 'ssantalk-admin-storage',
  AUTH_SESSION_STORAGE: 'ssantalk-session-storage',
  AUTH_TOKEN_NAME: 'ssantalk-token',
  PROBLEM_BASE_URL: 'http://www.problem.io',
  TOTAL_COUNT_HEADER_NAME: 'x-total-count',
  REQUESTED_URL_STORAGE_KEY: 'requested-url',
  VUE_APP_CHECK_PLUS_POPUP: process.env.VUE_APP_CHECK_PLUS_POPUP,
  CONTEXT_PATH: ''
};
