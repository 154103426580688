<template>
  <section id="loginFooter">
    <div class="wrapper">
      <div class="footer-header">
        <ul class="right-desc">
              <li class="list-inline-item"><a href="https://www.livrio.co.kr" target="_blank"
                  class="text-white-50 mr-4 ">리브리오 소개</a></li>
          <li><a @click="showPolicy">이용약관</a></li>
          <li><a @click="showPrivacy" >개인정보처리방침</a></li>
        </ul>
      </div>
      <div class="logo-div">
        <a href="javascript:void(0);" class="logo-footer">리브리오</a>
      </div>
      <div class="desc" style="margin-left:-15px">
        <ul class="footer-desc">
          <li>주식회사 리브리오</li>
          <li>대표 : 이상훈</li>
          <li>이메일 : <a href="mailto:3174mt@livrio.co.kr">3174mt@livrio.co.kr</a></li>
        </ul>
        <ul class="footer-desc">
          <li>사업자등록번호 : 648-88-01401
            <!-- <a>[사업자등록확인]</a> -->
          </li>
          <li>통신판매업 신고번호 : 제 2020-서울강남-01419 호</li>
          <li>
            대표번호 : 1811-0793
          </li>
        </ul>
        <ul class="footer-desc">
          <li>주소 : 서울시 강남구 논현로28길 48, 5층</li>
        </ul>
      </div>
    </div>
    <div class="mobile_footer">
      <div class="logo"> <a href="javascript:void(0);" class="logo-footer">리브리오</a></div>
      <div class="address">
        <p>주식회사 리브리오 |  대표 : 이상훈</p>
        <p>이메일 :3174mt@livrio.co.kr</p>
        <p>대표번호 :1811-0793</p>
        <p>사업자등록번호 : 648-88-01401</p>
        <p>통신판매업 신고번호 : 제 2020-서울강남-01419호</p>
        <p>서울시 강남구 논현로28길 48, 5층</p>
      </div>
      <div class="footer_menu">
        <a @click="showPolicy">이용약관</a> | <a @click="showPrivacy">개인정보처리방침</a>
      </div>
    </div>
        <ModalView v-if="isModalViewed">
          <component v-bind:is="modalCompo"  @close-modal="isModalViewed = false"></component>
        </ModalView>
  </section>

</template>

<script>
  import PolicyPopup from "@/views/member/PolicyPopup.vue";
  import PrivacyPopup from "@/views/member/PrivacyPopup.vue";
  import ModalView from "@/views/components/ModalView.vue";
  export default {
    name: 'Footer',
    components: {
      PolicyPopup,
      PrivacyPopup,
      ModalView,
    },
    data() {
      return {
        isModalViewed: false, // 모달 띄우기
        modalCompo: null, // 모달 콤포넌트
      }
    },
    methods: {
      showPolicy: function () {
        document.querySelector('body').scrollTo(0,0)
        this.$router.push({'name': 'PolicyPageTerms'})
        // this.modalCompo = PolicyPopup;
        // this.isModalViewed = true;
      },
      showPrivacy: function () {
        document.querySelector('body').scrollTo(0,0)
        this.$router.push({'name': 'PolicyPagePrivacy'})
        // this.modalCompo = PrivacyPopup;
        // this.isModalViewed = true;
      },
    }
  }

</script>

<style scoped>

#loginFooter {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: #202942;
  color: #adb5bd;
  padding: 10px 10% 30px 10%;
  font-size: 12px;
  border-top: 1px solid #283353;
}

  #loginFooter .footer-header,
  #loginFooter .logo-div {
    margin-bottom: 24px;
  }
  #loginFooter .footer-header a:hover{color:#ffffff;}
  #loginFooter ul {
    list-style-type: none;
    line-height: 220%;
  }

  #loginFooter .btn-group button {
    width: 32px;
    height: 32px;
    border: 1px solid #adb5bd;
    border-radius: 4px;
    margin-left: 5px;
    margin-top: 24px;
    padding: 6px;
  }

  #loginFooter .btn-group svg {
    width: 18px;
    height: 18px;
    fill: #adb5bd;

  }

  #loginFooter li {
    display: inline;
    margin-right: 15px;
  }

  #loginFooter .right-desc,
  #loginFooter .btn-group {
    float: right;
  }

  #loginFooter .right-desc li {
    margin-left: 15px;
  }

  #loginFooter .footer-desc li::before {
    content: "\007C";
    margin-right: 15px;
  }

  #loginFooter .footer-desc li:first-child::before {
    content: ""
  }
  .pop .pop_footer ::v-deep{
    padding: 10px 20px;
  }
</style>
