import {
  loginAsUser,
  loginAsPartner,
  accountAsUser
} from '@/api/login';
import {
  AUTH_PARTNER_STORAGE
} from '@/constants';
import logger from "@/plugins/logger";
import request from "@/plugins/http";

const userModule = {
  state: {
    failCount: 0,
    balance: 0,
    account: {
      loginName: "",
      userName: "",
      phoneNumber: "",
      partnerCode: '',
      masterCallerName: null,
      masterCallerNumber: null,
      callerNumber: [],

      userCorp: {
        userCorpId: null,
        corpName: null,
        corpRgstNmbr: null,
        corpZoneCode: null,
        corpRoadAddr: null,
        corpRemainAddr: null,
        corpKind: null,
        corpClass: null,
        corpContactNumber: null,
        corpContactMail: null,
        corpCert: null,
        corpTaxInvoiceFlag: false,
        rgstDate: null,
        mngrDate: null,
      }
    },
  },

  getters: {
    userName: (state) => state.account.userName,
    loginName: (state) => state.account.loginName,
    partnerType: (state) => state.account.partnerType,
    partnerCode: (state) => state.account.partnerCode,
    partnerStatus: (state) => state.account.partnerStatus,
    userFailCount: (state) => state.failCount,

    userCallerNumber: (state) => state.account.callerNumber,
    corpName : (state) => state.account.userCorp.corpName,
  },

  mutations: {
    SET_USER_INFO(state, payload) {
      logger.debug("set user info : " + JSON.stringify(payload));

      state.failCount = 0;
      state.account = payload;

      if (payload.callerNumber) {
        let masters = payload.callerNumber.filter(elm => elm.masterFlag);

        state.account.masterCallerName = masters.length > 0 ? masters[0].callerName : null;
        state.account.masterCallerNumber = masters.length > 0 ? masters[0].phoneNumber : null;
      }

      if (payload.userCorp) {
        if (payload.userCorp.corpAuditStatus === "S") {
          //
        } else {
          state.userCorp = {};
        }
      } else {

        state.userCorp = {};
      }

      logger.debug("save storage", payload);

      localStorage.setItem(AUTH_PARTNER_STORAGE, JSON.stringify(payload));
    },
    CLEAR_USER_INFO(state) {
      state.failCount = 0;
      state.account = {};
      state.sessionToken = null
      

      localStorage.removeItem(AUTH_PARTNER_STORAGE);
    },
    addFailCount(state) {
      state.failCount += state.failCount;
    },
  },
  actions: {
    async doReLogin(context, payload) {
      try {
        logger.debug("normal user login from local storage")
        // validate login
        await context.commit('SET_USER_INFO', payload);
        // navigate user main
        await context.dispatch('goMainPage', {
          pageName: "MemberDashboard"
        });
      } catch (err) {
        logger.warn('[vuex.user] doReLogin', err);
        return Promise.reject(err);
      }
    },
    // Login user
    async doUserLogin(context, payload) {
      try {
        const response = await loginAsUser(payload.id, payload.password);
        //logger.debug('[vuex.user] loginByUserId', payload, response);
        if (!response.success || response.failCount >= 5) {
          return response
        } else {
          // validate login
          if (!response['token']) {
            logger.debug("token null login fail");
            await context.commit('addFailCount');
          }

          logger.debug("token received");
          await context.dispatch('setSessionToken', {
            token: response['token']
          });

          const accountInfo = await accountAsUser();

          await context.commit('SET_USER_INFO', accountInfo);

          // navigate user main
          await context.dispatch('goMainPage', {
            pageName: "MemberDashboard"
          });
        }


      } catch (err) {
        console.error(err)
        logger.warn('[vuex.user] loginByUserId', err);
        return Promise.reject(err);
      }
    },
    async doPartnerLogin(context, payload) {
      try {
        const response = await loginAsPartner(payload.id, payload.password);
        //logger.debug('[vuex.user] loginByUserId', payload, response);
        if (!response.success || response.failCount >= 5) {
          return response
        } else {
          // validate login
          if (!response['token']) {
            logger.debug("token null login fail");
            await context.commit('addFailCount');
          }

          logger.debug("token received");

          await context.dispatch('setSessionToken', {
            token: response['token']
          });
         
          await context.commit('SET_USER_INFO', response);

          // const accountInfo = await accountAsUser();

          // await context.commit('SET_USER_INFO', accountInfo);

          // navigate user main
          await context.dispatch('goMainPage', {
            pageName: "MemberDashboard"
          });
        }


      } catch (err) {
        console.error(err)
        logger.warn('[vuex.user] loginByUserId', err);
        return Promise.reject(err);
      }
    },
    async doLogOut(context) {
      try {
        // if(await context.getters.getMsgContents !== ''){
        //   const confirm = window.confirm("작성중이던 내용이 있습니다. 정말 화면을 새로고침하시겠습니까?")
        //   if(!confirm){
        //     return;
        //   } else {
        //     await  context.commit('setMsgContents','');
        //   }
        // }
        logger.debug('[vuex.user] LogOut');
        const response = await request('/auth/logout', 'get');
        console.log('response :', response);
        if(response.error){
          alert("로그아웃 실패");
          return;
        }
        console.log("logout success");
        await context.commit('CLEAR_USER_INFO', {});
        await context.dispatch('setSessionToken', {
          token: null
        });
        await context.dispatch('goMainPage', {
          pageName: "Main"
        });
      } catch (err) {
        logger.warn('[vuex.user] LogOut', err);
      }
    },
  },
};

export default userModule;
