import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutCommon from '@/views/layout/anonymous/Layout'
import LayoutMember from '@/views/layout/member/Layout'

// let recommendCode = null

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Intro',
    // component: () => import('../views/tutorial/Main'),
    redirect: { name: 'Main'},
    // alias: [`/${recommendCode}`]
  },
  
  {
    path: '/',
    component: LayoutCommon,
    children: [
      {
        path: '/about',
        component: () => import('../views/About'),
        name: 'About',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      // {
      //   path: '/main',
      //   component: () => import('../views/tutorial/Main'),
      //   name: 'Main',
      //   meta: {
      //     title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
      //   }
      // },
      // {
      //   path: '/main/:recommendCode',
      //   component: () => import('../views/tutorial/Main'),
      //   // redirect: { name: 'Main'},

      // },


    ]
  },
  {
    path: '/user',
    component: LayoutMember,
    children: [
      {
        path: '/main',
        component: () => import('../views/member/Main'),
        name: 'Main',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/main/:recommendCode',
        component: () => import('../views/member/Main'),
        // redirect: { name: 'Main'},

      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('../views/member/Login'),
      },
      {
        path: '/sign_up',
        component: () => import('../views/member/SignUp'),
        name: 'SignUp',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/sign_partner',
        component: () => import('../views/member/SignUpPartner'),
        name: 'SignUpPartner',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/signup_again',
        component: () => import('../views/member/SignUpAgain'),
        name: 'SignUpAgain',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/signup_agree',
        component: () => import('../views/member/SignUpAgree'),
        name: 'SignUpAgree',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/signup_done',
        component: () => import('../views/member/SignUpDone'),
        name: 'SignUpDone',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/find_id',
        component: () => import('../views/member/FindId'),
        name: 'FindId',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/password_reset',
        component: () => import('../views/member/PasswordReset'),
        name: 'PasswordReset',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
  
      {
        path: '/dashboard',
        component: () => import('../views/member/DashBoard'),
        name: 'MemberDashboard',
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/partner-edit-info',
        component: () => import('../views/member/PartnerEditInfo'),
        name: 'PartnerEditInfo',
        props : true,
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      // {
      //   path: '/member-withraw-done',
      //   component: () => import('../views/member/MemberWithdrawDone'),
      //   name: 'MemberWithdrawDone',
      //   props : true,
      //   meta: {
      //     title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
      //   }
      // },
      {
        path: '/partner-withraw',
        component: () => import('../views/member/PartnerWithraw'),
        name: 'PartnerWithraw',
        props : true,
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/member-announce-info',
        component: () => import('../views/member/MemberAnnounce'),
        name: 'MemberAnnounce',
        props : true,
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/report_partner',
        component: () => import('../views/member/ReportPartner'),
        name: 'ReportPartner',
        props : true,
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/report_customer',
        component: () => import('../views/member/ReportCustomer'),
        name: 'ReportCustomer',
        props : true,
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/report_profit',
        component: () => import('../views/member/ReportProfit'),
        name: 'ReportProfit',
        props : true,
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/report_payment',
        component: () => import('../views/member/ReportPayment'),
        name: 'ReportPayment',
        props : true,
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },

      {
        path: '/policy_terms_of_use',
        component: () => import('../views/member/PolicyPageTerms'),
        name: 'PolicyPageTerms',
        props : true,
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      {
        path: '/policy_operation',
        component: () => import('../views/member/PolicyPageOperation'),
        name: 'PolicyPageOperation',
        props : true,
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      }, {
        path: '/policy_privacy',
        component: () => import('../views/member/PolicyPagePrivacy'),
        name: 'PolicyPagePrivacy',
        props : true,
        meta: {
          title: 'API', icon: 'mdi-view-dashboard', noCache: true, affix: true
        }
      },
      
      {
        path: "/404",
        component: () => import('../views/member/NotFound'),
        name: "NotFound",
      },
      {
        path: '/*',
        redirect: { name: 'NotFound' },
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
