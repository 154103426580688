import request from '@/plugins/http';


/**
 * Login by email and password
 *
 * @param {String} userId user id
 * @param {String} password user password
 */
export const loginAsUser = async (userId, password) => {
  console.log(`[loginAsUser] user id : ${userId}`);

  return await request('/auth/login', 'post', {
      id: userId,
      pwd: password,
      loginType: 'user'
    }
  );
};
export const loginAsPartner = async (userId, password) => {
  return await request('/auth/login', 'post', {
      id: userId,
      pwd: password,
      loginType: 'partner'
    }
  );
};
export const accountAsUser = async () => {
  console.log(`[accountAsUser] user id`);

  return await request('/api/user/account', 'get');
};

export const loginAsAdmin = async (userId, password) => {
  console.log(`[loginAsAdmin] admin id : ${userId}`);

  return await request('/auth/login', 'post', {
      id: userId,
      pwd: password,
      loginType: 'admin'
    }
  );
};

export const accountAsAdmin = async () => {
  console.log(`[accountAsAdmin] user id`);

  return await request('/api/mng/account', 'get');
};

/**
 * Get user information by token
 *
 * @param {String} token user token
 */
export const updateUserPassword = async (token) => {
  console.log(`[getUserInfo] token ${token}`);
  const {data} = await request({
    url: '/api/user/account',
  });
  console.log('account', data);
  return {
    user: {
      user: data.id,
      roles: [...data.authorities],
      ...data,
    },
  };
};
