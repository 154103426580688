<template>
  <transition name="modal">
    <div class="pop_bg">
    <div class="pop"
      v-bind:class="[{pop_md: modalSizeUp}, pop_sm]">
      <div class="pop_body">
        <slot name="body"/>
      </div>
      <div class="pop_footer">
         <a class="btL-md" @click="closeModal()">{{ closeName }}</a>
      </div>
    </div>
    </div>
  </transition>
</template>

<script>
import ModalHandler from "@/classes/ModalHandler";

export default {
  name: "AlertPopup",
  props: {
    title: { type: String },
    dialog: { type: ModalHandler },
    closeName: { type: String },
    modalSizeUp : {type: String},
  },
  data: () => ({
  }),
  computed: {},
  methods: {
    closeModal () {
     this.$emit('close');
    },

  },
};
</script>

