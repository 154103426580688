/* eslint-disable no-irregular-whitespace */
<template>
  <transition name="modal">
    <div class="pop pop_md" id="popup_policy">
      <div class="pop_head">
        <h2>서비스 이용약관</h2><a class="btn_pop_close" @click="closeModal">닫기</a>
      </div>
      <div class="pop_body">

        <div class="scroll_body">
          <div>
            
<p>제<span lang="EN-US">1</span>조<span lang="EN-US"> (</span>목적<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>싼톡 파트너 이용약관<span lang="EN-US">(</span>이하<span lang="EN-US"> “</span>본
약관<span lang="EN-US">)”</span>은 주식회사 리브리오<span lang="EN-US"> (</span>이하<span lang="EN-US"> “</span>회사<span lang="EN-US">”)</span>가 싼톡 파트너에게 회사가 지정하는 서비스<span lang="EN-US">(</span>이하<span lang="EN-US"> “</span>물품<span lang="EN-US">”)</span>의 판매업무를
위탁함에 있어 필요한 권리<span lang="EN-US">·</span>의무를 정함에 그 목적이 있습니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">2</span>조<span lang="EN-US"> (</span>본 약관의 효력
및 변경<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>①본 약관은 파트너가 본 약관에 동의하고 회사가 정한 절차에 따라 등록함으로써 효력이 발생합니다<span lang="EN-US">.</span></p>
<p>②본 약관에 동의한 파트너는 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며<span lang="EN-US">, </span>그 적용에 동의한 것으로 봅니다<span lang="EN-US">.</span></p>
<p>③회사는 본 약관<span lang="EN-US">(</span>파트너 이용정책 포함<span lang="EN-US">)</span>을 개정하는 경우에는 개정된 약관과 사유를 적용일자<span lang="EN-US"> 7</span>일<span lang="EN-US">(</span>단<span lang="EN-US">, </span>수정 내용이 파트너에게 불리한 경우에는<span lang="EN-US"> 30</span>일<span lang="EN-US">) </span>전까지 파트너에게 전화<span lang="EN-US">, </span>문자메시지<span lang="EN-US">, </span>이메일<span lang="EN-US">, </span>회사가 정한 공지채널<span lang="EN-US">(SNS)
</span>등의 방법으로 고지하며<span lang="EN-US">, </span>파트너가 그 기간 안에 이의를 제기하지 않는 경우<span lang="EN-US">, </span>본 약관의 개정안에 동의한 것으로 봅니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">3</span>조<span lang="EN-US"> (</span>본 약관의 기간
및 해지<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>①본 약관은 본 약관의 효력이 발생한 때부터 회사 또는 파트너에 의해 본 약관이 해지되는 시점에 종료됩니다<span lang="EN-US">.</span></p>
<p>② 회사와 파트너는 상호 합의하는 경우 본 약관에 따른 계약을 해지할 수 있습니다<span lang="EN-US">.</span></p>
<p>③ 파트너는 언제든지 어떠한 사유로든 파트너 사이트에서의 탈퇴로 본 약관을 해지할 수 있습니다<span lang="EN-US">. </span></p>
<p>④ 본 조 제<span lang="EN-US">5</span>항에서 정하는 경우를 제외하고는<span lang="EN-US">, </span>회사는 파트너가 본 약관 제<span lang="EN-US">7</span>조 주의의무를 위반하는 경우<span lang="EN-US">, </span>이에 대한 시정요구를<span lang="EN-US"> 7</span>일 간의 유예기간을 두고 사전에 통지합니다<span lang="EN-US">. </span>이때<span lang="EN-US">, </span>시정조치가 이루어지지 않는 경우 파트너 자격의 제한 및 파트너
페이지에 대한 접속권한 상실ㆍ제한<span lang="EN-US">, </span>계약해지 등의 조치가 취해질 수 있음을 함께 통지하고 미시정 시
조치할 수 있습니다<span lang="EN-US">.</span></p>
<p>⑤ 전항에도 불구하고 회사는 파트너가 아래 각 호에 해당하는 경우<span lang="EN-US">, </span>전항에
따른 시정조치를 위한 유예기간을 조정하거나 즉시 조치한 후 사후 통지할 수 있습니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US" style="color:red;">1. </span><span style="color:red;">본 약관 제<span lang="EN-US">12</span>조상 파트너의 의무를 위반하는 경우</span></p>
<p><span lang="EN-US" style="color:red;">2. </span><span style="color:red;">회사에 대한 허위사실을 유포하는 경우</span></p>
<p><span lang="EN-US" style="color:red;">3. </span><span style="color:red;">중대범죄 혐의로 수사가 개시된 경우</span></p>
<p><span lang="EN-US" style="color:red;">4. </span><span style="color:red;">다수의 클레임 발생 등 고객 보호 및 서비스 품질 유지를 위해 필요하다고 판단되는 경우</span></p>
<p><span lang="EN-US" style="color:red;">5. </span><span style="color:red;">기타 불법행위 및 객관적으로 정상적인 업무 수행이 불가하다고 판단되는 경우</span></p>
<p><span lang="EN-US" style="color:red;">6. </span><span style="color:red;">그 외 파트너 정책에서 정하는 사유</span></p>
<p><span lang="EN-US"><br></span></p>
<p>⑥ 본 약관이 해지되는 경우<span lang="EN-US">, </span>회사는 파트너에게 부여된 모든 혜택을
철회할 수 있으며<span lang="EN-US">, </span>해지 사유 및 해당 해지 사유의 해소 여부를 고려하여 파트너의 재등록을 승인하지
않을 수 있습니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">4</span>조<span lang="EN-US"> (</span>회사 및 파트너
간 관계<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>회사와 파트너는 본 약관에 따라 서비스판매 업무를 수행하고 수수료를 지급하는데 있어 상호 대응한 입장에서 신의성실의
원칙에 따라 자신의 권리를 행사하며 의무를 이행합니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">5</span>조<span lang="EN-US"> (</span>파트너 자격 제한
등<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>회사는 본 약관에 따라 등록을 신청하는 파트너가 다음 각 호의 어느 하나에 해당하는 경우에는 등록을 승인하지
않을 수 있고<span lang="EN-US">, </span>파트너의 자격 및 파트너 사이트의 접속 권한을 상실ㆍ제한 할 수 있습니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US">1. </span>회사와의 거래관계에서 계약 조건 및 관련 법률<span lang="EN-US">, </span>규정 등을 위반한 경우</p>
<p><span lang="EN-US">2. </span>파트너가 제공한 정보에 허위 정보<span lang="EN-US">, </span>정보 누락<span lang="EN-US">, </span>오타 등이 포함되어 있는 경우</p>
<p><span lang="EN-US">3. </span>타인 명의로 등록하거나 실명으로 등록하지 않은 경우</p>
<p><span lang="EN-US">4. </span>파트너가 미성년자인 경우</p>
<p><span lang="EN-US">5. </span>본 약관 및 정책을 위반하여 회사가 본 약관을 해지하거나 파트너가
본 약관을 해지한 이력이 있는 경우</p>
<p><span lang="EN-US">6. </span>회사가 요청하는 증빙서류 등을 제출하지 아니하는 경우</p>
<p><span lang="EN-US">7. </span>기타 이에 준하는 사유로서 회사가 등록을 승인하지 않는 것이
적절하다고 판단하는 경우</p>
<p><span lang="EN-US">8. </span>그 외 파트너 정책에서 정하는 사유</p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">6</span>조<span lang="EN-US"> (</span>위탁 수수료 등<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>① 회사는 파트너에게 다음 각 호의 기준에 따라 위탁 수수료를 지급합니다<span lang="EN-US">. </span>수수료
산정기준은 회사가 정한 기준으로 사전에 공지하며<span lang="EN-US">, </span>변경 시<span lang="EN-US"> 7</span>일
이전에 공지합니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US">1. </span>기본 수수료<span lang="EN-US">(</span>세전
금액 기준<span lang="EN-US">) : </span>파트너가 유치한 고객 문자발송 금액 <span lang="EN-US">3.5% </span>및
하위 유치 파트너의 고객사 문자발송 금액의 <span lang="EN-US">1%. </span>정산완료 성공기준으로 지급됩니다<span lang="EN-US">.<span>  </span><span> </span></span></p>
<p>② 회사는 위탁 수수료 외에 별도의 프로모션에 따라 인센티브를 지급할 수 있으며<span lang="EN-US">, </span>위 프로모션은 파트너에 대한 전화<span lang="EN-US">, </span>문자메시지<span lang="EN-US">, </span>이메일<span lang="EN-US">, </span>회사가 정한 공지채널<span lang="EN-US">(SNS)
</span>등의 방법으로 실시간 공지될 수 있습니다<span lang="EN-US">.</span></p>
<p>③ 본 조에 의한 위탁 수수료 등은 파트너의 사업소득에 해당하여<span lang="EN-US">, </span>회사는
소득세법 제<span lang="EN-US">127</span>조 제<span lang="EN-US">1</span>항 제<span lang="EN-US">3</span>호<span lang="EN-US">, </span>동법 시행령 제<span lang="EN-US">184</span>조
제<span lang="EN-US">1</span>항 등<span lang="EN-US">(</span>본 약관 효력 발생 이후 각 법령이 개정되는 경우에는
개정 후의 해당 조항<span lang="EN-US">)</span>에서 정한 바에 따라 본 조에 따른 위탁 수수료 등의 일부<span lang="EN-US">(3.3%)</span>를 원천징수 합니다<span lang="EN-US">.</span></p>
<p><span>④</span> 회사는 매월<span lang="EN-US"> 1</span>일부터 말일 사이에 발생한 영업 수수료에 대해 <span lang="EN-US">5</span>만원을
초과할 경우 <span lang="EN-US">20</span>일 이내에 파트너가 지정한 본인 명의계좌로 지급합니다<span lang="EN-US">.
</span>단<span lang="EN-US">, </span>지급 기한일이 토요일 또는 공휴일인 경우<span lang="EN-US">, </span>그
직후의 은행 영업일까지 지급합니다<span lang="EN-US">.</span></p>
<p style="margin-left:10.0pt;text-indent:-10.0pt;"><span>⑤</span> 회사는 파트너사이트를
운영하며 파트너의 수수료 적립 내역을 상시 열람할 수 있도록 합니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">7</span>조<span lang="EN-US"> (</span>파트너 자격의
유지<span lang="EN-US">)</span></p>
<p style="margin-left:10.0pt;text-indent:-10.0pt;">파트너는 지속적으로 신규 영업 또는 신규 파트너 모집을 하여야 하며<span lang="EN-US">, 1</span>년이상 신규 실적이
없는 경우는 파트너 계약이 해지됩니다<span lang="EN-US">. </span></p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">8</span>조<span lang="EN-US"> (</span>보안 및 비밀
유지 등<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>① 회사는 본 약관을 통하여 습득한 파트너에 관한 정보나 자료를 파트너의 사전 동의 없이 제<span lang="EN-US">3</span>자에게 제공<span lang="EN-US">, </span>공표 또는 누설하여서는 안 됩니다<span lang="EN-US">.</span></p>
<p>② 파트너는 본 약관을 통하여 습득한 회사 또는 회사의 고객에 관한 정보나 자료를 회사의 사전 동의 없이 녹취<span lang="EN-US">(</span>녹음<span lang="EN-US">), </span>사진촬영<span lang="EN-US">, </span>복제<span lang="EN-US">, </span>저장하거나<span lang="EN-US">, </span>제<span lang="EN-US">3</span>자에게
제공<span lang="EN-US">, </span>공표 또는 누설하여서는 안 됩니다<span lang="EN-US">.</span></p>
<p>③ 파트너가 본 약관에 따른 영업의 목적을 위해서만 회사의 파트너 사이트를 사용하여야 하며<span lang="EN-US">, </span>그 과정에서 회사 또는 회사의 고객 정보가 제<span lang="EN-US">3</span>자에게 유출되지 않도록
보안 유지의무를 다하여야 합니다<span lang="EN-US">.</span></p>
<p>④ 파트너는 회사의 상호<span lang="EN-US">, </span>상표 및 기타 특정될 수 있는 표지를 회사의
사전 동의 없이 본 약관의 이행을 위한 목적 외로 사용하여서는 안 됩니다<span lang="EN-US">.</span></p>
<p><span>⑤</span> 본 조에 따른 의무는 본 약관이 종료된 후에도 유효하게 적용됩니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US"><span> </span></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">9</span>조<span lang="EN-US"> (</span>개인정보 보호<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>① 파트너는 영업중 알게 된 고객의 정보를 영업 업무 이외의 목적으로 사용할 수 없습니다<span lang="EN-US">.</span></p>
<p>② 파트너의 귀책사유로 회사의 고객의 개인정보 등이 분실<span lang="EN-US">, </span>도난<span lang="EN-US">, </span>유출<span lang="EN-US">, </span>위<span lang="EN-US">·</span>변조되는 등의
사고가 발생하고 그로 인하여 개인정보 주체를 비롯한 제<span lang="EN-US">3</span>자와 회사 사이에 민<span lang="EN-US">·</span>형사 또는 행정상 분쟁이 발생한 경우<span lang="EN-US">, </span>파트너는 이를 자신의 책임과
비용으로 해결하고<span lang="EN-US">, </span>회사를 면책시켜야 합니다<span lang="EN-US">. </span>다만 관련
법률에 따라 회사가 책임을 부담하여야 하는 경우 또는 회사의 고의<span lang="EN-US">∙</span>과실이 입증된 경우에는 회사가 책임을
분담합니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">10</span>조<span lang="EN-US"> (</span>양도 등의 제한<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>파트너는 본 약관에 따른 지위<span lang="EN-US">, </span>권리 및 의무의 전부 또는 일부를
제<span lang="EN-US">3</span>자에게 양도<span lang="EN-US">, </span>재위탁하거나 담보의 목적물로 제공할 수
없습니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">11</span>조<span lang="EN-US"> (</span>사고의 책임과
손해배상<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>① 회사 또는 파트너가 본 약관을 위반하여 상대방 또는 제<span lang="EN-US">3</span>자가 손해를
입은 경우<span lang="EN-US">, </span>귀책 있는 회사 또는 파트너는 상대방 또는 제<span lang="EN-US">3</span>자가
입은 손해 전부를 배상해야 합니다<span lang="EN-US">.</span></p>
<p><span>②</span> 회사는 파트너에 대한 손해배상채권이 있는 경우<span lang="EN-US">, </span>파트너에게 지급할 위탁 수수료 등에서 해당 손해배상채권과
상계할 수 있습니다<span lang="EN-US">.</span></p>
<p><span>③</span> 본 조에 따른 의무는 본 약관이 종료된 후에도 유효하게 적용됩니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">12</span>조<span lang="EN-US"> (</span>면책<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>① 회사 또는 파트너가 천재지변 기타 이에 준하는 불가항력적인 사유로 인하여 본 약관상 의무를 이행하지 못하는
경우 상대방에 대한 손해배상책임이 면책됩니다<span lang="EN-US">.</span></p>
<p><span lang="EN-US"><br></span></p>
<p><span lang="EN-US"><br></span></p>
<p>제<span lang="EN-US">13</span>조<span lang="EN-US"> (</span>분쟁해결<span lang="EN-US">)</span></p>
<p><span lang="EN-US"><br></span></p>
<p>본 약관에 명시되지 않은 사항 및 양 당사자의 해석이 다른 사항에 대하여는 상법 기타 상관례를 바탕으로 한 상호
합의에 따라 해결함을 원칙으로 하며<span lang="EN-US">, </span>원만한 해결이 이루어지지 않을 경우 민사소송법에 따른 관할법원에서
분쟁을 해결합니다<span lang="EN-US">.<br>
<br>
</span></p>

          </div>
        </div>
      </div>
      <div class="pop_footer">
        <a class="bt-md-ra" @click="closeModal">닫기</a>
      </div>
    </div>
  </transition>
</template>

<script>
  import ModalHandler from "@/classes/ModalHandler";

  export default {
    name: "PolicyPopup",
    props: {
      title: {
        type: String
      },
      dialog: {
        type: ModalHandler
      },
      closeName: {
        type: String
      },
      modalSizeUp: {
        type: String
      },
    },
    data: () => ({}),
    computed: {},
    methods: {
      closeModal() {
        console.log('close-modal')
        this.$emit('close-modal');
      },

    },
  };

</script>
<style scoped>
  .pop_body .scroll_body {
    overflow: auto;
    height: 300px;
  }

</style>
